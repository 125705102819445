.Header {
    background-color: white;
    box-shadow: 0px 1px 10px rgba(199, 199, 199, 0.452);
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1;

    .logo {
        img {
            height: 40px;
            cursor: pointer;
        }
    }

    .nav {
        display: flex;
        align-items: center;
        position: relative;
        right: 20px;

        a, Link {
            padding: 0px 10px;
            margin: 0px 5px;
            text-decoration: none;
        }
    }

    a, .nav-link {
        text-decoration: none;
        color: #141c3a;
    }
    
    a:hover, .nav-link:hover {
        text-decoration: underline;
        cursor: pointer;
    }
    
}

@media (max-width: 992px) {
    .Header {
        width: 100vw;
        font-size: 18px;

        .nav {
            right: 10px;
        }
    }
}
  
@media (max-width: 600px) {
    .Header {
        width: 100vw;
        font-size: 14px;
        padding: 5px 5px;
        
        .nav a {
            padding: 0px 6px;
        }
    }
}