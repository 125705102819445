.Mission, .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Mission {
    min-height: 50vh;
    margin: 0;
    padding: 0;

    background-color: #141c3a;
    color: #141c3a;

    p {
        font-size: 1.3rem;
    }

    .inner {
        width: 80vw;
        height: 45vh;
        background-color: #fff;
        text-align: center;
        
        border-radius: 12px;
        box-shadow: 0px 1px 10px rgba(199, 199, 199, 0.452);

        p {
            width: 70%;
        }

        p:first-of-type {
            font-size: 1.8rem;
            margin-top: 0;
        }

    }

}

@media (max-width: 992px) {
    .Mission {
        .inner {
            width: 85vw;
            height: 50vh;

            p {
                width: 90%;
            }
    
            p:first-of-type {
                font-size: 1.8rem;
            }
        }
    }
}
  
@media (max-width: 600px) {
    .Mission {
        .inner {
            height: 50vh;

            p {
                width: 90%;
                font-size: 1.1rem;
            }
    
            p:first-of-type {
                font-size: 1.4rem;
            }
        }
    }
}

@media screen and (orientation:landscape)
and (min-device-width: 568px) 
and (max-device-width: 823px) {
  .Mission {
      .inner {
          height: 70vh;
      
          p {
              font-size: 1.1rem;
              margin: 0 0 5vh 0;
          }
      }
  }
}