.Projects-Body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #141c3a;
  color: #fff;
  min-height: 110vh;
  padding-top: 10vh;

  .Projects {
    width: 60vw;
    max-width: 80vw;
    max-height: 88vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;

    h2 {
      font-weight: normal;
      font-size: 1.8rem;
      padding-bottom: 1rem;
    }

    p {
      font-size: 1rem;
      width: 575px;
      max-width: 85vw;
      margin: 3vh;

      a {
        text-decoration: none;
        color: #fff;
        cursor: pointer;
      }

      a:hover {
        text-decoration: underline;
        color: gainsboro;
      }
    }

    .links {
      text-align: center;
    }
    
    .slideshow-container {
      width: 75vw;
      max-width: fit-content;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
        
      -webkit-animation-name: player;
      -webkit-animation-duration: 1.5s;
      animation-name: player;
      animation-duration: 1.5s;
      
      @-webkit-keyframes player {
        from {opacity: .4} 
        to {opacity: 1}
      }
      
      @keyframes player {
        from {opacity: .4} 
        to {opacity: 1}
      }
    }
    
    button {
      padding: 0;
      border: none;
      background: none;

      margin: 20px;
      font-size: 1.6rem;
      color: #fff;
      cursor: pointer;
    }
      
    .prev:hover, .next:hover {
      color: rgb(201, 198, 198);
    }
    
    @media only screen and (max-width: 300px) {
      .prev, .next {font-size: 11px}
    }
  }
}

@media (max-width: 992px) {
  .Projects-Body {
    min-height: 95vh;

    .Projects {
      max-width: 100vw;

      .slideshow-container {
        width: 90vw;
  
        button {
          font-size: 1.3rem;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .Projects-Body {
    width: 100vw;

    .Projects {
      width: 98vw;

      .slideshow-container {
        width: 95vw;
  
        button {
          font-size: 1rem;
        }
      }
    }
  }
}

@media screen and (orientation:landscape)
and (min-device-width: 568px) 
and (max-device-width: 823px) {
   .Projects-Body {
      .Projects {
        width: 90vw;

        .slideshow-container {
          width: 55vw;
        }

        .desc {
          p:first-of-type, .links {
            width: 90vw;
          }
        }
      }
   }
}

@media (min-width: 1365px) {
  .Projects-Body {
    min-height: 120vh;
  }
}
