.About {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;

    background-color: #141c3a;
    color: #fff;

    p {
        font-size: 1.3rem;
        padding: 0 20vw;
        // text-indent: 50px;
        text-align: center;
    }
    
    p:first-of-type {
        font-size: 1.8rem;
        text-indent: 0;
    }
}

// @media (max-width: 1024px) {
//     .About {
//     }
//   }
  
@media (max-width: 992px) {

    .About {
        p {
            padding: 0 12vw;
            margin: 0 0 3vh 0;
        }
    }

}
  
@media (max-width: 600px) {
    .About {
        min-height: 75vh;
        margin-top: 3vh;

        p {
            font-size: 1.1rem;
            margin: 0 0 2vh 0;
        }
        
        p:first-of-type {
            font-size: 1.3rem;
            width: 85vw;
        }
    }
}

@media screen and (orientation:landscape)
and (min-device-width: 568px) 
and (max-device-width: 823px) {
  .About {
    height: 100vh;
    padding-top: 3rem;

    p {
        font-size: 1.1rem;
        margin: 0 0 5vh 0;

    }
  }
}